import { css } from '@emotion/react'
import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'

import { ButtonLink } from '../../components/common/ButtonLink'
import checkIcon from '../../images/check-icon.svg'
import topAboutIcon01 from '../../images/top-about-icon_1.svg'
import topAboutIcon02 from '../../images/top-about-icon_2.svg'
import topAboutIcon03 from '../../images/top-about-icon_3.svg'
import { columns } from '../../styles/layout.styles'
import { mq, rem } from '../../styles/mixin.styles'
import { colors, space } from '../../styles/variables.styles'

/**
 * Overview
 *
 * @returns overview section
 */
export const Overview = () => (
  <section css={overviewStyle}>
    <div className="about-section">
      <h2>
        パレット調達業務を一気通貫でサポート。
        <br className="is-md-only is-lg-only" />
        大幅な業務効率化を実現します。
      </h2>
      <p>
        これまでのパレット調達業務はさまざまな調整業務や手戻りが頻発し非効率な業務が行われていました。また業務の属人化によって、担当者ごとに品質が異なり、業務の効率化や標準化が進まない状況でした。iPaSはこれまでのパレット調達業務を見直し、入口から出口までを一気通貫で繋ぎ、システムによるサポートで属人化を防ぐことで、大幅な業務効率化を実現するツールです。
      </p>
      <div className="about-section__image">
        <StaticImage
          src="../../images/top-about_1.jpg"
          width={392}
          height={221}
          placeholder="none"
          alt="パソコンの前に座っているスーツを着た人と周囲に複数のパレットのイメージ"
          loading="eager"
        />
      </div>
    </div>
    <ul className="about-section__list" css={columns}>
      <li>
        <div className="about-section__list__image">
          <img src={topAboutIcon01} width={220} height={146} alt="構想図作成の簡略化のイメージ図" />
        </div>
        <h3>構想図作成の簡略化</h3>
        <p>データストックされた過去事例やテンプレートによってパレット構想図の作成が簡略化できます。</p>
      </li>
      <li>
        <div className="about-section__list__image">
          <img src={topAboutIcon02} width={220} height={146} alt="リアルタイム共有のイメージ図" />
        </div>
        <h3>リアルタイム共有</h3>
        <p>複数の担当間で作業進捗や発生する課題などをリアルタイムで共有することができます。</p>
      </li>
      <li>
        <div className="about-section__list__image">
          <img src={topAboutIcon03} width={220} height={146} alt="過去データの参照のイメージ図" />
        </div>
        <h3>過去データの参照</h3>
        <p>過去に手配した履歴やコスト（パレットメーカーの見積もり金額）を簡単に参照できます。</p>
      </li>
    </ul>
    <div className="about-section about-section--left">
      <h2>
        一元管理で情報を集約・蓄積することで
        <br className="is-md-only is-lg-only" />
        パレット調達業務の改善が期待できます。
      </h2>
      <ul className="about-section__check-list">
        <li>
          <img src={checkIcon} width="30" height="31" alt="" />
          各フローの業務軽減
        </li>
        <li>
          <img src={checkIcon} width="30" height="31" alt="" />
          業務の標準化・可視化
        </li>
        <li>
          <img src={checkIcon} width="30" height="31" alt="" />
          業務制度の向上
        </li>
        <li>
          <img src={checkIcon} width="30" height="31" alt="" />
          調達コストカット
        </li>
      </ul>
      <div className="about-section__image">
        <StaticImage
          src="../../images/top-about_2.png"
          width={208}
          height={216}
          placeholder="none"
          alt="書類を持っているスーツを着た人のイラスト"
        />
      </div>
      <div className="about-section__button">
        <ButtonLink link="feature" style="primary">
          iPaSの特徴を見る
        </ButtonLink>
      </div>
    </div>
  </section>
)

const overviewStyle = css`
  display: grid;
  row-gap: clamp(${rem(20)}, 3vw, ${rem(80)});
  .about-section {
    h2 {
      grid-area: heading;
      font-size: clamp(${rem(24)}, 3vw, ${rem(32)});
    }
    p {
      grid-area: description;
      line-height: 2;
      padding-top: clamp(${space.sm}, 3vw, ${space.md});
    }
    .about-section__image {
      grid-area: image;
      margin-top: clamp(${space.md}, 3vw, 0rem);
      text-align: center;
    }
  }
  .about-section__list {
    margin-top: clamp(${rem(20)}, 3vw, 0rem);
    row-gap: clamp(${rem(20)}, 3vw, ${rem(40)});
    list-style: none;
    .about-section__list__image {
      text-align: center;
      img {
        max-width: 100%;
      }
    }
    > li {
      border: ${rem(4)} solid ${colors.lightColor.hex};
      border-radius: ${rem(16)};
      padding: clamp(${rem(3)}, 1.2vw, ${rem(32)}) clamp(${rem(12)}, 1.2vw, ${rem(25)})
        clamp(${rem(12)}, 1.2vw, ${rem(32)});
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    }
    h3 {
      text-align: center;
      margin-top: clamp(${rem(2)}, 1.2vw, ${rem(34)});
    }
    p {
      font-size: clamp(${rem(13)}, 1.2vw, ${rem(16)});
      line-height: clamp(${rem(19)}, 1.2vw, 2);
      margin-top: clamp(${rem(4)}, 1.2vw, ${rem(29)});
    }
  }
  .about-section--left {
    display: grid;
    grid-template-columns: 0.75fr 1.25fr;
    grid-template-areas:
      'heading heading'
      'image check-list'
      'button button';
    align-items: center;
    column-gap: clamp(${rem(10)}, 2vw, ${rem(72)});
    margin-top: clamp(${rem(20)}, 4vw, 0rem);
    > h2 {
      font-size: clamp(${rem(20)}, 3vw, ${rem(32)});
      line-height: clamp(${rem(29)}, 4vw, ${rem(46)});
    }
    .about-section__check-list {
      list-style: none;
      display: grid;
      grid-area: check-list;
      grid-auto-rows: max-content;
      gap: clamp(${rem(11)}, 2vw, ${rem(20)});
      > li {
        font-size: clamp(${rem(16)}, 2vw, ${rem(24)});
        font-weight: 700;
        display: flex;
        column-gap: ${rem(10)};
      }
    }
    .about-section__image {
      margin-left: -${rem(40)};
    }
  }
  .about-section__button {
    grid-area: button;
    margin-top: clamp(${rem(20)}, 4vw, ${rem(50)});
  }
  ${mq('md')} {
    .about-section {
      display: grid;
      grid-template-areas:
        'heading image'
        'description image';
      align-items: end;
      grid-template-columns: 1fr min(40%, ${rem(392)});
      p {
        padding-right: clamp(${rem(16)}, 3vw, ${rem(28)});
      }
      .about-section__check-list {
        margin-top: ${rem(40)};
      }
    }
    .about-section__list {
      > li {
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
      }
    }
    .about-section--left {
      grid-template-areas:
        'image heading'
        'image check-list'
        'button button';
      align-items: start;
      grid-template-columns: min(30%, ${rem(320)}) 1fr;
      .about-section__image {
        padding-left: clamp(${rem(30)}, 3vw, ${rem(50)});
        margin-left: 0;
      }
      .about-section__check-list {
        grid-template-columns: 1fr 1fr;
      }
    }
  }
`
