import { css } from '@emotion/react'
import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'

import { ButtonLink } from '../../components/common/ButtonLink'
import { useHeaderScroll } from '../../hooks/useHeaderScroll'
import bgImg1x from '../../images/hero-bg-1x.jpg'
import bgImg1xWebP from '../../images/hero-bg-1x.webp'
import bgImgMin1x from '../../images/hero-bg-min-1x.jpg'
import bgImgMin1xWebP from '../../images/hero-bg-min-1x.webp'
import bgImgMin from '../../images/hero-bg-min.jpg'
import bgImgMinWebP from '../../images/hero-bg-min.webp'
import bgImg from '../../images/hero-bg.jpg'
import bgImgWebP from '../../images/hero-bg.webp'
import { mq, rem } from '../../styles/mixin.styles'
import { colors } from '../../styles/variables.styles'

/**
 * Hero
 *
 * @returns hero
 */
export const Hero = () => {
  const scrollActive = useHeaderScroll(400)
  return (
    <section css={heroStyle} className={scrollActive ? '' : 'is-scroll'}>
      <div className="hero__container">
        <div className="hero__text">
          <h2 className="hero__title--desktop">
            <span>パレットの荷姿構想から調達までの</span>
            <span>一連のプロセスを支援し</span>
            <span>一元管理できるシステムです</span>
          </h2>

          <h2 className="hero__title--mobile">
            <span>パレットの荷姿構想から調達</span>
            <span>までの一連のプロセスを支援し</span>
            <span>一元管理できるシステムです</span>
          </h2>

          <div className="hero__button">
            <ButtonLink link="contact" style="primary-yellow">
              iPaS導入のご相談
            </ButtonLink>
          </div>
        </div>
        <div className="hero__image">
          <StaticImage
            src="../../images/hero-image.png"
            width={549}
            height={335}
            alt="パレット調達支援システム iPaS 画面イメージ"
            placeholder="none"
            loading="eager"
          />
        </div>
      </div>
    </section>
  )
}

const heroStyle = css`
  width: 100%;
  position: relative;
  background: linear-gradient(253.19deg, rgba(0, 116, 174, 0) 60.33%, rgba(0, 135, 203, 0.49) 79.73%, #0090d9 94.93%);
  height: 100%;
  overflow: hidden;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-image: url(${bgImgMin1x});
    background-image: image-set(
      url(${bgImgMin1xWebP}) 1x,
      url(${bgImgMinWebP}) 2x,
      url(${bgImgMin1x}) 1x,
      url(${bgImgMin}) 2x
    );
    background-size: cover;
    background-position: center;
    background-color: ${colors.reMainBlue.hex};
  }
  .hero__container {
    display: grid;
    grid-template-areas:
      'hero-image'
      'hero-text';
    max-width: ${rem(1270)};
    margin: 0 auto;
    padding: ${rem(123)} clamp(${rem(20)}, 3vw, ${rem(30)}) clamp(${rem(58)}, 3vw, ${rem(79)});
    position: relative;
  }
  &.is-scroll {
    .hero__container {
      padding-top: ${rem(74)};
    }
  }
  .hero__text {
    display: flex;
    grid-area: hero-text;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: clamp(0rem, 3vw, ${rem(22)});
  }
  .hero__button {
    width: 100%;
    max-width: 100%;
    margin: clamp(${rem(45)}, 3vw, ${rem(66)}) auto 0;
  }
  h2 {
    font-size: clamp(${rem(24)}, 2.65vw, ${rem(38)});
    line-height: 1.75;
    letter-spacing: 0.03em;
    color: ${colors.white.hex};
    margin-top: clamp(${rem(14)}, 3vw, ${rem(0)});
    span {
      display: block;
      transform: matrix(0.98, 0, -0.19, 1, 0, 0) translateX(${rem(5)});
    }
    strong {
      font-size: ${rem(28)};
      display: inline-block;
      position: relative;
    }
  }
  .hero__image {
    grid-area: hero-image;
    padding: 0 ${rem(16)};
    text-align: center;
  }
  .hero__title--mobile {
    display: block;
    white-space: nowrap;
    text-align: center;
  }
  .hero__title--desktop {
    display: none;
  }

  ${mq('md')} {
    &::before {
      background-image: url(${bgImg1x});
      background-image: image-set(url(${bgImg1xWebP}) 1x, url(${bgImgWebP}) 2x, url(${bgImg1x}) 1x, url(${bgImg}) 2x);
      background-position: right;
    }
    .hero__container {
      grid-template-areas: 'hero-text hero-image';
      grid-template-areas: 1fr min(40vw, ${rem(549)});
      column-gap: clamp(${rem(20)}, 2.65vw, ${rem(36)});
    }
    .hero__button {
      max-width: ${rem(320)};
      margin-left: 0;
      margin-right: 0;
    }
    .hero__title--desktop {
      display: block;
    }
    .hero__title--mobile {
      display: none;
    }
    h2 {
      strong {
        font-size: clamp(${rem(20)}, 2.65vw, ${rem(38)});
      }
      &::before {
        bottom: ${rem(10)};
      }
    }
  }

  ${mq('lg')} {
    .hero__button {
      margin-top: ${rem(20)};
    }
    .hero__container {
      padding-top: ${rem(153)};
      .hero__text {
        margin-top: ${rem(17)};
      }
    }
    .hero__image {
      padding-left: ${rem(37)};
    }
    &.is-scroll {
      .hero__container {
        .hero__text {
          margin-top: 0;
        }
      }
    }
  }
`
