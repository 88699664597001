import { css } from '@emotion/react'
import { mq, rem } from './mixin.styles'
import { breakpoints } from './variables.styles'

export const columns = css`
  display: grid;
  gap: ${rem(38)};
  ${mq('sm')} {
    grid: auto-flow / repeat(2, calc((min(100%, ${breakpoints.md}px) - ${rem(38)} * 1) / 2));
  }
  ${mq('md')} {
    grid: auto-flow / repeat(3, calc((min(100%, ${breakpoints.lg}px) - ${rem(38)} * 2) / 3));
  }
`
