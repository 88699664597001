import { css } from '@emotion/react'
import React from 'react'
import { mq, rem } from '../../styles/mixin.styles'
import { colors } from '../../styles/variables.styles'

type SectionTitleProps = {
  titleJa: string
  titleEn: string
}

/**
 * SectionTitle
 *
 * @param SectionTitleProps card props
 * @returns Card contents
 */
export const SectionTitle = ({ titleJa, titleEn }: SectionTitleProps) => {
  return (
    <div css={sectionTitleStyle}>
      <p className="section-title__en">{titleEn}</p>
      <h2 className="section-title__ja">{titleJa}</h2>
    </div>
  )
}

const sectionTitleStyle = css`
  display: grid;
  row-gap: clamp(0rem, 1vw, ${rem(11)});
  text-align: center;
  .section-title__ja {
    line-height: 1.45;
  }
  .section-title__en {
    font-size: ${rem(14)};
    line-height: 1;
    font-weight: 700;
    letter-spacing: 0.2em;
    font-family: var(--roman-font-family);
    color: ${colors.mainBlue.hex};
  }

  ${mq('lg')} {
    .section-title__en {
      font-size: ${rem(16)};
      letter-spacing: 0.25em;
    }
  }
`
